<template>
  <ValidationObserver ref="nswSdeForm">
    <div class="card card-custom">
      <div class="card-body container">

        <div class="card-body p-0">
          <!--begin: Wizard-->
          <div id="nswSdeFormWizard" class="wizard wizard-3" data-wizard-state="step-first">
            <!--begin: Wizard Body-->
            <div class="card card-custom card-shadowless rounded-top-0">
              <div class="card-body p-0">
                <div class="row justify-content-center py-8 px-8 py-lg-10 px-lg-10">
                  <div class="col-xl-12 col-xxl-12">
                    <!--begin: Wizard Form-->
                    <div class="form mt-0" id="kt_form">

                      <!--begin: ERROR ALERT-->
                      <b-alert v-if="errorAlert" variant="warning" show>
                        <div class="text-dark" v-html="errorAlert"></div>
                      </b-alert>
                      <!--end: ERROR ALERT-->

                      <b-alert v-if="formData && isReadOnly" variant="secondary" show>
                        <div class="text-dark">This form is already submitted and cannot be modified.</div>
                      </b-alert>

                      <b-card v-if="formData" border-variant="primary">

                        <div class="form-group row mb-2">
                          <label class="col-2 col-form-label font-weight-bold font-size-h5 text-right">Name of the Insured</label>
                          <div class="col-6">
                            <ValidationProvider rules="required" name="insuredName" v-slot="{ errors }">
                              <input type="text" class="form-control" v-model="formData.insuredName" maxlength="255" disabled/>
                              <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </div>
                        </div>
                        <div class="form-group row mb-2">
                          <label class="col-2 col-form-label font-weight-bold font-size-h5 text-right">Address</label>
                          <div class="col-6">
                            <ValidationProvider rules="required" name="insuredAddress" v-slot="{ errors }">
                              <input type="text" class="form-control" v-model="formData.insuredAddress" disabled/>
                              <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </div>
                        </div>

                        <h4 class="font-weight-bold text-primary mt-10">
                          NSW Insurance Stamp Duty Exemption Declaration
                        </h4>
                        <div class="form-group col-12">
                          <ul class="ml-5">
                            <li>
                              From 1 January 2018 eligible small businesses (generally with less than $2million turnover) will not
                              be required to pay duty on certain types of insurance, including:
                              Commercial vehicle insurance – for a motor vehicle used primarily for business purposes
                            </li>
                            <li>
                              Occupational indemnity insurance (or professional indemnity) – covering liability arising out of the
                              provision of professional or other services (other than medical indemnity insurance)
                            </li>
                            <li>
                              Product and public liability insurance – insurance covering liability for personal injury or property
                              damage occurring in connection with, or arising out of the products or services of, a business
                            </li>
                          </ul>
                        </div>

                        <h4 class="font-weight-bold text-primary mt-5">
                          What is a small business?
                        </h4>
                        <div class="form-group col-12">
                          <ul class="ml-5">
                            <li>
                              You are a small business for the current year if: you carry on a business in the current year; and
                            </li>
                            <li>
                              one or both of the following applies:
                              <ol class="ml-5">
                                <li>
                                  you carried on a business in the previous year and your aggregated turnover for that previous
                                  year was less than $2million;
                                </li>
                                <li>
                                  your aggregated turnover for the current year is likely to be less than $2million.
                                </li>
                              </ol>
                            </li>
                          </ul>
                        </div>

                        <h4 class="font-weight-bold text-primary mt-5">
                          Declaration
                        </h4>
                        <div class="form-group col-12 mb-1">
                          <span class="font-weight-bold">
                            I declare that I am a small business eligible for the exemption from the requirement to pay
                            duty on certain types of insurance under section 259B of the Duties Act 1997 (NSW).<span class="text-danger">*</span>
                          </span>
                          <div class="col-2 pl-0">
                            <ValidationProvider rules="required" name="declaration" v-slot="{ errors }">
                              <b-form-radio-group size="lg" v-model="formData.declaration" class="form-control" :disabled="isReadOnly">
                                <b-form-radio value="Yes"><span class="font-weight-bold">Yes</span></b-form-radio>
                                <b-form-radio class="ml-2" value="No"><span class="font-weight-bold">No</span></b-form-radio>
                              </b-form-radio-group>
                              <span class="text-danger"><br>{{ errors[0] }}</span>
                            </ValidationProvider>
                          </div>
                        </div>

                        <div class="form-group row mb-2">
                          <label class="col-2 col-form-label font-weight-bold text-right">First Name<span class="text-danger">*</span></label>
                          <div class="col-3">
                            <ValidationProvider rules="required" name="firstName" v-slot="{ errors }">
                              <input type="text" class="form-control" v-model="formData.personCompletingForm.firstName" maxlength="255"
                                     :disabled="isReadOnly"/>
                              <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </div>
                          <label class="col-2 col-form-label font-weight-bold text-right">Last Name<span class="text-danger">*</span></label>
                          <div class="col-3">
                            <ValidationProvider rules="required" name="lastName" v-slot="{ errors }">
                              <input type="text" class="form-control" v-model="formData.personCompletingForm.lastName" maxlength="255"
                                     :disabled="isReadOnly"/>
                              <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </div>
                        </div>
                        <div class="form-group row mb-2">
                          <label class="col-2 col-form-label font-weight-bold text-right">Position<span class="text-danger">*</span></label>
                          <div class="col-3">
                            <ValidationProvider rules="required" name="position" v-slot="{ errors }">
                              <input type="text" class="form-control" v-model="formData.personCompletingForm.position" maxlength="255"
                                     :disabled="isReadOnly"/>
                              <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </div>
                          <label class="col-2 col-form-label font-weight-bold text-right">Email<span class="text-danger">*</span></label>
                          <div class="col-3">
                            <ValidationProvider rules="required" name="email" v-slot="{ errors }">
                              <input type="email" class="form-control" v-model="formData.personCompletingForm.email" maxlength="100"
                                     :disabled="isReadOnly"/>
                              <span class="text-danger">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </div>
                        </div>
                        <div class="form-group row mb-1">
                          <label class="col-2 col-form-label font-weight-bold text-right">Date</label>
                          <div class="col-3">
                            <input v-if="!isReadOnly" type="text" class="form-control" v-model="today" disabled/>
                            <input v-if="isReadOnly" type="text" class="form-control" v-model="formData.submissionDate" disabled/>
                          </div>
                        </div>
                        <div class="form-group row mb-3">
                          <label class="col-2 col-form-label font-weight-bold text-right">Signature<span class="text-danger">*</span></label>
                            <div class="col-10">
                              <!-- Signature Pad-->
                              <div v-if="!isReadOnly">
                                <VueSignaturePad width="400px" height="200px" :customStyle="signaturePadCustomStyle"
                                                 ref="nswSdeFormSignaturePad" :options="{ onBegin }" />
                                <div v-if="formData.signatureData && formData.signatureData.isEmpty === true">
                                  <span class="text-danger">Signature cannot be empty</span>
                                </div>
                                <div>
                                  <button v-if="!isReadOnly" @click.prevent="clearSignature()"
                                          class="btn btn-outline-primary btn-sm mt-2"
                                  >
                                    Clear Signature
                                  </button>
                                </div>
                              </div>

                              <div v-if="isReadOnly && hasSignatureData">
                                <img :src="formData.signatureData.data" alt="Signature" width="500px" height="200px" :class="signaturePadCustomStyle"/>
                              </div>
                          </div>
                        </div>

                        <div v-if="brand === 'RENTSURE'" class="form-group row mb-1 mt-10">
                          <label class="col-12 text-muted ">
                            <span class="text-primary">Rentsure Pty Ltd</span> ABN 98 059 004 634 Authorised Representative No. 416045 <br/>
                            Level 3, 345 Pacific Highway, North Sydney, NSW 2060 | PO Box 1670, North Sydney, NSW 2059 <br/>
                            <span class="text-primary">Office:</span> (02) 9460 2200 | 1800 355 646
                            <span class="text-primary">Facsimile:</span> (02) 9460 2111
                            <span class="text-primary">Email:</span> insurance@rentsure.com.au
                            <span class="text-primary">Claims:</span> claims@rentsure.com.au
                            <span class="text-primary">Web:</span> www.rentsure.com.au <br/>
                            Rentsure Pty Limited is a specialist underwriting agency who acts as agent at law for
                            Insurance Australia Limited ABN 11 000 016 722, AFSL 227681 trading as CGU Insurance.<br/>
                            An Authorised Representative of Delaney Kelly Golding Pty Ltd AFS Licence No. 231146, ABN 35 000 663 221
                          </label>
                        </div>

                        <div v-if="brand === 'CRI'" class="form-group row mb-1 mt-10">
                          <label class="col-12 text-muted ">
                            <span class="text-primary">Car Rental Insurance Pty Limited</span> ABN 39 112 849 135 Authorised Representative No. 321550 <br/>
                            Level 3, 345 Pacific Highway, North Sydney, NSW 2060 | PO Box 1670, North Sydney, NSW 2059 <br/>
                            <span class="text-primary">Office:</span> (02) 9460 0404 | 1300 675 050
                            <span class="text-primary">Facsimile:</span> 1300 675 151
                            <span class="text-primary">Email:</span> insurance@crinsurance.com.au
                            <span class="text-primary">Claims:</span> claims@crinsurance.com.au
                            <span class="text-primary">Web:</span> www.crinsurance.com.au <br/>
                            Car Rental Insurance Pty Ltd is a specialist underwriting agency who acts as agent at law for Insurance Australia Limited
                            ABN 11 000 016 722 AFSL 227681 trading as CGU Insurance.
                          </label>
                        </div>

                        <div v-if="brand === 'FUSE'" class="form-group row mb-1 mt-10">
                          <label class="col-12 text-muted ">
                            <span class="text-primary">Fuse Fleet Underwriting Pty Ltd</span> | ABN 46 662 436 624 (Fuse Fleet) is a Corporate Authorised Representative (No. 001299656) of Delaney Kelly Golding Pty Ltd |<br/>
                            ABN 35 000 663 221 | AFSL No. 231146 (DKG). DKG has an underwriting agency agreement with the insurer, Insurance Australia Limited | ABN 11 000 016 722 |
                            AFSL No. 227681 trading as CGU Insurance and DKG has sub-authorised Fuse Fleet to act on its behalf when carrying out some of the services under the
                            underwriting agency agreement.<br/>
                            <span class="text-primary">Office:</span> 1300 387 338
                            <span class="text-primary">Email:</span> insurance@fusefleet.com.au
                            <span class="text-primary">Claims:</span> claims@fusefleet.com.au
                            <span class="text-primary">Web:</span> www.fusefleet.com.au <br/>
                          </label>
                        </div>

                      </b-card>

                      <!--begin: Wizard Actions -->
                      <div class="d-flex justify-content-between border-top pt-8">
                        <div>
                          <button class="btn btn-success font-weight-bold text-uppercase px-8 py-4"
                                  v-if="!isReadOnly" @click.prevent="submitForm"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                      <!--end: Wizard Actions -->
                    </div>
                    <!--end: Wizard Form-->
                  </div>
                </div>
              </div>
            </div>
            <!--end: Wizard Body-->
          </div>
          <!--end: Wizard-->
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-3.scss";

.page-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.step-disabled {
  cursor: not-allowed !important;
}

body.swal2-shown > [aria-hidden="true"] {
  transition: 0.1s filter;
  filter: blur(10px);
}

</style>

<script>
import KTWizard from "@/assets/js/components/wizard";
import _ from "lodash";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import KTUtil from "@/assets/js/components/util";
import {ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME} from "@/core/services/store/htmlclass.module";
import BrandService from "@/core/services/brand.service";

export default {
  name: "NSWStampDutyExemptionForm",

  data() {
    return {
      serverUrl: null,
      errorAlert: null,
      formId: null,
      brand: null,
      formData: null,
      wizardObj: null
    };
  },

  mounted() {
    this.startPageLoading();

    // Extract information from URL params
    const queryParams = this.$route.query;
    if (!queryParams.formId) {
      this.handleError("Unauthorised access!");
      return;
    }

    // Extract data from URL params
    this.formId = queryParams.formId || null;

    this.serverUrl = `${window.location.origin}/services`;
    if(process.env.NODE_ENV === 'development') {
      this.serverUrl = `http://localhost:3000/services`;
    }
    this.$apiService.setServerUrl(this.serverUrl);

    this.$store.commit("setWizardTitle", `NSW Stamp Duty Exemption`);

    // Get form data.
    this.getFormData();
  },

  computed: {
    isReadOnly: function () {
      const status = _.get(this.formData, 'formStatus');
      return status !== 'Initialised' && status !== 'Incomplete';
    },

    today: function () {
      return moment.tz('Australia/Sydney').format('DD/MM/YYYY');
    },

    signaturePadCustomStyle: function () {
      return {
        border: "lightgray 1px solid"
      };
    },

    hasSignatureData: function() {
      return this.formData && this.formData.signatureData && this.formData.signatureData.data
          && String(this.formData.signatureData.data).startsWith('data:image/png;base64');
    }
  },

  methods: {

    getFormData: function () {
      this.$apiService.get(`${this.serverUrl}/nsw-sde/form/${this.formId}`)
          .then(({ data }) => {
            this.formData = data.result;
            this.brand = _.toUpper(this.formData.brand);

            // Set UI Brand
            BrandService.setUIBrand(this.brand);

            // Initialise the UI wizard
            this.initWizard();

            this.stopPageLoading();
          })
          .catch((error) => {
            this.handleError(error);
          });
    },

    initWizard: function () {
      // Initialize form wizard
      const wizard = new KTWizard("nswSdeFormWizard", {
        startStep: 1, // Initial active step number
        clickableSteps: false, // Do not allow step clicking
      });

      this.wizardObj = wizard;

      // Change event - Scroll to top of the page
      wizard.on("change", (/*wizardObj*/) => {
        setTimeout(function () {
          KTUtil.scrollTop();
        }, 500);
      });
    },

    onBegin: function() {
      this.$refs.nswSdeFormSignaturePad.resizeCanvas();
    },

    clearSignature: function() {
      this.$refs.nswSdeFormSignaturePad.clearSignature();

    },

    submitForm: async function () {
      if(this.isReadOnly) {
        return;
      }

      const isFormValid = await this.$refs.nswSdeForm.validate();
      this.formData.signatureData = this.$refs.nswSdeFormSignaturePad.saveSignature();
      if(!isFormValid || !this.formData.signatureData || this.formData.signatureData.isEmpty === true) {
        return;
      }

      this.startPageLoading();
      this.$apiService.post(`${this.serverUrl}/nsw-sde/form/${this.formId}/submit`, this.formData)
          .then(() => {
            this.stopPageLoading();
            this.formData.formStatus = 'Active';

            Swal.fire({
              titleText: "Submission Successful",
              html: `<p>Thank you for submitting this form.</p><br/>
                     <span class="font-weight-bold">Please close this browser window.</span>`,
              icon: "success",
              width: "40rem",
              allowOutsideClick: false,
              allowEnterKey: false,
              allowEscapeKey: false,
              showConfirmButton: false,
              showCancelButton: false,
              showCloseButton: false
            });
          })
          .catch((error) => {
            this.handleError(error);
          });
    },

    setError: function (message) {
      KTUtil.scrollTop();
      this.errorAlert = message;
    },

    clearError: function () {
      this.errorAlert = null;
    },

    handleError: function(errorObj) {
      this.stopPageLoading();
      let errorMsg = null;

      if(errorObj) {
        let responseStatus = _.get(errorObj, "response.status");
        let errorMessage = errorObj.message || '';
        if (responseStatus === 401 || errorMessage.includes("status code 401") ||
            responseStatus === 403 || errorMessage.includes("status code 403")
        ) {
          errorMsg = "This session is invalid or expired. Please close this window.";
        }
        else {
          let responseResult = _.get(errorObj, "response.data.result");
          if (_.isString(responseResult)) {
            let applicationErrorPrefix = "UI_ERROR:";

            if (responseResult.startsWith(applicationErrorPrefix)) {
              errorMsg = responseResult.substring(applicationErrorPrefix.length, responseResult.length);
            }
          }
        }
      }
      if (!errorMsg) {
        console.log(errorObj);
        errorMsg = "An unexpected error has occurred.";
      }

      this.setError(errorMsg);
      KTUtil.scrollTop();
    },

    startPageLoading: function() {
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
    },

    stopPageLoading: function() {
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }
  },
};
</script>
